<template>
  <div>
    <div class="price-main-box">
      <div class="first-box">
        <div class="title-box">快速搭建企业全要素地图</div>
        <div class="text-box">
          鸿象提供免费版、标准版、高级版、旗舰版4个版本。旗舰版开放API接口，企业可以基于接口进行功能定制开发。
        </div>
        <div class="card-box">
          <div>
            <div class="card-title-box">免费版</div>
            <div class="card-level-title">初创团队首选</div>

            <div class="card-price-box">
              <span class="symbol-box">¥</span>
              <span class="num-box">0</span>
              <span class="time-box">/人/月</span>
            </div>

            <div class="card-text-box">支持10用户</div>

            <div class="card-describe-box">
              <div>多人协作</div>
              <div>数据上图</div>
              <div>地址解析</div>
              <div>空间分析</div>
              <div>1万条单表数据量</div>
            </div>

            <div class="card-button-box" @click="use">
              免费使用
            </div>
          </div>

          <div>
            <div class="card-title-box">标准版</div>
            <div class="card-level-title">空间分析更进一步</div>

            <div class="card-price-box">
              <span class="symbol-box">¥</span>
              <span class="num-box">9.9</span>
              <span class="time-box">/人/月</span>
            </div>

            <div class="card-text-box">支持20用户</div>

            <div class="card-describe-box">
              <div>包含免费版能力</div>
              <div>无限制空间分析</div>
              <div>10GB云端存储空间</div>
              <div>3万条单表数据量上限</div>
            </div>

            <div class="card-level-button">
              <div class="first-button">升级咨询</div>
              <div class="second-button">立即购买</div>
            </div>
          </div>

          <div>
            <div class="card-title-box">高级版</div>
            <div class="card-level-title">全面态势感知能力</div>

            <div class="card-price-box">
              <span class="symbol-box">¥</span>
              <span class="num-box">49.9</span>
              <span class="time-box">/人/月</span>
            </div>

            <div class="card-text-box">支持100用户</div>

            <div class="card-describe-box">
              <div>包含标准版能力</div>
              <div>物联网设备上图</div>
              <div>50GB云端存储空间</div>
              <div>10万条单表数据量</div>
            </div>

            <div class="card-level-button">
              <div class="first-button">升级咨询</div>
              <div class="second-button">立即购买</div>
            </div>
          </div>

          <div>
            <div class="card-title-box">旗舰版</div>
            <div class="card-level-title">私有化部署，系统无缝连接</div>

            <div class="card-price-box">
              <span class="symbol-box">联系客服</span>
            </div>

            <div class="card-text-box">不限用户人数</div>

            <div class="card-describe-box">
              <div class="black">三维地图</div>
              <div class="black">AI空间分析</div>
              <div class="black">行业分析算法</div>
              <div class="black">高级系统安全</div>
              <div class="black">SSO单点登录</div>
            </div>

            <div class="card-button-box card-button2-box">升级咨询</div>
          </div>
        </div>
      </div>

      <div class="second-box">
        <div class="title-box">版本对比</div>
        <div class="table-box">
          <div class="table-title-box">
            <div></div>
            <div>免费版</div>
            <div>标准版</div>
            <div>高级版</div>
            <div class="table-last-box">旗舰版</div>
          </div>

          <div class="table-body-title">
            工作空间
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in firstTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            数据服务
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in secondTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            空间分析
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in thirdTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            实验室（暂未上线）
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in fourthTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            系统安全
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in fifthTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            服务支持
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in sixthTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>

          <div class="table-body-title">
            定制化
          </div>
          <div
            class="table-body-content"
            v-for="(item, index) in seventhTable"
            :key="item.title"
          >
            <div class="table-content-title">{{ item.title }}</div>
            <div>{{ item.complimentary }}</div>
            <div>{{ item.standard }}</div>
            <div>{{ item.senior }}</div>
            <div class="table-last-box">{{ item.flagship }}</div>
          </div>
        </div>
      </div>

      <div class="thirty-box">
        <div class="title-box">常见问题</div>
        <div class="content-box">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <div class="collapse-title-box">
                  <div class="yuan"></div>
                  <div class="collapse-text-box">商业版和企业版的区别？</div>
                </div>
              </template>
            </el-collapse-item>

            <el-collapse-item name="2">
              <template slot="title">
                <div class="collapse-title-box">
                  <div class="yuan"></div>
                  <div class="collapse-text-box">
                    商业版和企业版只支持按年售卖么？
                  </div>
                </div>
              </template>
            </el-collapse-item>

            <el-collapse-item name="3">
              <template slot="title">
                <div class="collapse-title-box">
                  <div class="yuan"></div>
                  <div class="collapse-text-box">
                    线上购买支持哪些支付方式？
                  </div>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from "../home/ContactUs";
export default {
  name: "price", // 开放能力

  components: {
    ContactUs,
  },

  data() {
    return {
      activeNames: [],

      firstTable: [
        {
          title: "协作人数",
          complimentary: "1人",
          standard: "20人",
          senior: "100人",
          flagship: "不限",
        },
        {
          title: "工作台数量",
          complimentary: "1人",
          standard: "3人",
          senior: "10人",
          flagship: "不限",
        },
        {
          title: "图层数量",
          complimentary: "50个/工作台",
          standard: "100个/工作台",
          senior: "200个/工作台",
          flagship: "不限",
        },
        {
          title: "地图加栽",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "工具箱",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "空间搜索",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "三维地图",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
      ],

      secondTable: [
        {
          title: "公共数据集",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "数据管理（数据编辑、检索）",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "数据录入（Excel、CSV格式导入）",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "数据集接口",
          complimentary: "✓",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "单表数据量",
          complimentary: "1万条",
          standard: "3万条",
          senior: "10万条",
          flagship: "不限",
        },
        {
          title: "地址解析服务",
          complimentary: "100条/月",
          standard: "3000条/月",
          senior: "1万条/月",
          flagship: "3万条/月",
        },
        {
          title: "附件（上传图片、文件等）",
          complimentary: "—",
          standard: "10G/团队",
          senior: "50G/团队",
          flagship: "不限",
        },
        {
          title: "物联网数据（支持视频监控、定位器、传感器等设备）",
          complimentary: "—",
          standard: "—",
          senior: "✓",
          flagship: "✓",
        },
      ],

      thirdTable: [
        {
          title: "基础空间分析",
          complimentary: "试用",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "AI空间分析",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "行业分析算法",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
      ],

      fourthTable: [
        {
          title: "多人标绘",
          complimentary: "—",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "时空任务",
          complimentary: "—",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "智能卡片",
          complimentary: "—",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "要素关联",
          complimentary: "—",
          standard: "✓",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "视频联动",
          complimentary: "—",
          standard: "—",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "视频融合",
          complimentary: "—",
          standard: "—",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "视界切换",
          complimentary: "—",
          standard: "—",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "视界跟随",
          complimentary: "—",
          standard: "—",
          senior: "✓",
          flagship: "✓",
        },
      ],

      fifthTable: [
        {
          title: "数据权限",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "数据回收站",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "操作日志",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
      ],

      sixthTable: [
        {
          title: "在线客服",
          complimentary: "9:00 - 18:00（工作日）",
          standard: "9:00 - 18:00（工作日）",
          senior: "7*12小时",
          flagship: "7*12小时",
        },
        {
          title: "专属技术群",
          complimentary: "在线客服",
          standard: "在线客服",
          senior: "✓",
          flagship: "✓",
        },
        {
          title: "产品培训",
          complimentary: "—",
          standard: "—",
          senior: "可选",
          flagship: "可选",
        },
        {
          title: "数据汇聚服务",
          complimentary: "联系客服",
          standard: "联系客服",
          senior: "联系客服",
          flagship: "联系客服",
        },
        {
          title: "遥感影像服务",
          complimentary: "—",
          standard: "—",
          senior: "联系客服",
          flagship: "联系客服",
        },
        {
          title: "建模服务",
          complimentary: "—",
          standard: "—",
          senior: "联系客服",
          flagship: "联系客服",
        },
      ],

      seventhTable: [
        {
          title: "自定义域名",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "自定义登录页",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "隐藏版权信息",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "SSO/LDAP",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
        {
          title: "私有化部署",
          complimentary: "—",
          standard: "—",
          senior: "—",
          flagship: "✓",
        },
      ],
    };
  },

  methods: {
    use() {
      window.open("https://app.hongxiangai.com");
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
.price-main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 1180px;
  }
  .first-box {
    width: 100%;
    height: 872px;
    background-image: linear-gradient(180deg, #0272c91a 0%, #ffffff00 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    & > div {
      width: 1180px;
    }
    .title-box {
      margin-bottom: 40px;
      font-family: HarmonyOS_Sans;
      font-weight: SC;
      font-size: 36px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 40px;
    }
    .text-box {
      margin-bottom: 80px;
      font-family: HarmonyOS_Sans;
      font-weight: SC;
      font-size: 16px;
      color: #393c40;
      letter-spacing: 0;
      text-align: center;
      line-height: 26px;
    }
    .card-box {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 265px;
        height: 526px;
        background: #ffffff;
        box-shadow: 0 3px 10px 0 #161b2726;
        border-radius: 8px;
        padding: 32px 24px 24px;
        text-align: center;
        position: relative;
        .card-title-box {
          font-family: HarmonyOS_Sans;
          font-weight: 500;
          font-size: 28px;
          color: #1e1e1e;
          letter-spacing: 0;
          text-align: center;
          line-height: 28px;
          margin-bottom: 16px;
        }
        .card-level-title {
          font-family: HarmonyOS_Sans;
          font-weight: SC;
          font-size: 16px;
          color: #1e1e1e;
          letter-spacing: 0;
          text-align: center;
          line-height: 26px;
          margin-bottom: 30px;
        }
        .card-price-box {
          margin-bottom: 10px;
          .symbol-box {
            width: 18px;
            height: 30px;
            font-family: HarmonyOS_Sans;
            font-weight: 500;
            font-size: 30px;
            color: #1e1e1e;
            letter-spacing: 0;
            text-align: center;
            line-height: 30px;
            margin-right: 7px;
          }
          .num-box {
            height: 42px;
            font-family: HarmonyOS_Sans;
            font-weight: 700;
            font-size: 42px;
            color: #1e1e1e;
            letter-spacing: 0;
            text-align: center;
            line-height: 42px;
            margin-right: 6px;
          }
          .time-box {
            width: 45px;
            height: 26px;
            font-family: HarmonyOS_Sans;
            font-weight: SC;
            font-size: 16px;
            color: #1e1e1e;
            letter-spacing: 0;
            text-align: center;
            line-height: 26px;
          }
        }
        .card-text-box {
          height: 26px;
          font-family: HarmonyOS_Sans;
          font-weight: SC;
          font-size: 16px;
          color: #1e1e1e;
          letter-spacing: 0;
          text-align: center;
          line-height: 26px;
          margin-bottom: 40px;
        }
        .card-describe-box {
          display: flex;
          flex-direction: column;
          gap: 10px;
          text-align: start;
          & > div {
            height: 26px;
            font-family: HarmonyOS_Sans;
            font-weight: SC;
            font-size: 16px;
            color: #797c80;
            letter-spacing: 0;
            line-height: 26px;
          }
          & > div::before {
            content: "\2713";
            margin-right: 12px;
            font-size: small;
            color: #2fd25d;
          }
          .black::before {
            color: #1e1e1e;
          }
        }
        .card-button-box {
          cursor: pointer;
          width: 217px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #0f80dc;
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 16px;
          color: #0f80dc;
          letter-spacing: 0;
          text-align: center;
          line-height: 44px;
          position: absolute;
          bottom: 24px;
        }
        .card-button2-box {
          border: 1px solid #1e1e1e;
          background: #1e1e1e;
          color: #ffffff;
        }
        .card-level-button {
          position: absolute;
          bottom: 24px;
          width: 217px;
          height: 44px;
          display: flex;
          justify-content: space-between;
          & > div {
            width: 100px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .first-button,
          .second-button {
            cursor: pointer;
            background: #0f80dc;
            font-family: HarmonyOS_Sans;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            letter-spacing: 0;
          }
          .second-button {
            background: #ffffff;
            border: 1px solid #0f80dc;
            color: #0f80dc;
          }
        }
      }
    }
  }

  .second-box {
    background: #ffffff66;
    padding-bottom: 80px;
    .title-box {
      font-family: HarmonyOS_Sans;
      font-weight: SC;
      font-size: 36px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 40px;
      margin-bottom: 80px;
    }
    .table-last-box {
      box-shadow: inset 0 -1px 0 0 #ebecf0 !important;
    }
    .table-title-box,
    .table-body-content {
      background: #f9fafb;
      display: flex;
      & > div {
        width: 236px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset -1px -1px 0 0 #ebecf0;
        font-family: HarmonyOS_Sans;
        font-weight: 500;
        font-size: 22px;
        color: #1e1e1e;
        letter-spacing: 0;
      }
    }
    .table-title-box {
      height: 60px;
    }
    .table-body-content {
      background: #ffffff;
      & > div {
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }
      .table-content-title {
        justify-content: start;
        padding: 13px 64px 13px 24px;
        font-weight: 500;
      }
    }
    .table-body-title {
      height: 60px;
      line-height: 60px;
      background: #f9fafb;
      box-shadow: inset 0 -1px 0 0 #ebecf0;
      font-family: HarmonyOS_Sans;
      font-weight: 500;
      font-size: 20px;
      color: #1e1e1e;
      letter-spacing: 0;
      padding-left: 24px;
    }
  }

  .thirty-box {
    width: 100%;
    background: #f1f2f666;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 1180px;
    }
    .title-box {
      font-family: HarmonyOS_Sans;
      font-weight: SC;
      font-size: 36px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 40px;
      margin-bottom: 80px;
    }

    .content-box {
      /deep/.el-collapse {
        border: 0;
        .el-collapse-item {
          .el-collapse-item__header {
            border: 0;
            height: 80px;
            background-color: #f9fafb;
            box-shadow: inset 0 -1px 0 0 #ebecf0;
            line-height: 80px;
            .collapse-title-box {
              display: flex;
              align-items: center;
              padding-left: 24px;
              gap: 16px;
              .yuan {
                width: 10px;
                height: 10px;
                background: #0f80dc;
                border-radius: 50%;
              }
              .collapse-text-box {
                font-family: HarmonyOS_Sans;
                font-weight: 500;
                font-size: 20px;
                color: #1e1e1e;
                letter-spacing: 0;
              }
            }
            .collapse-title-box:hover {
              .collapse-text-box {
                color: #0f80dc;
              }
            }
          }
          .el-collapse-item__wrap {
            background-color: #f9fafb;
            border: 0;
          }
        }
      }
    }
  }
}
</style>
